import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import NewlineFormatter from '../components/newlineFormatter'
import Img from 'gatsby-image'
import './recipe.scss'

function renderPictureColumn(picture) {
  if (!picture) {
    return null
  }

  return (
    <div class="left-column">
      <Img fluid={picture.childImageSharp.fluid} alt="" />
    </div>
  )
}

const RecipePage = ({ data }) => {
  const recipe = data.strapiRecipe

  return (
    <Layout title={recipe.title}>
      <div className="recipe-page">
        <div className="container">
          <div className="title">
            <div>
              <h1>{recipe.title}</h1>
              <div>Portioner: {recipe.servings} st</div>
            </div>
          </div>
          <div className="row">
            {renderPictureColumn(recipe.picture)}
            <div className="middle-column">
              <div>
                <h2>Ingredienser</h2>
                <NewlineFormatter>{recipe.ingredients}</NewlineFormatter>
              </div>
            </div>
            <div className="right-column">
              <h2>Gör så här</h2>
              <NewlineFormatter>{recipe.instructions}</NewlineFormatter>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RecipePage

export const pageQuery = graphql`
  query RecipeQuery($slug: String!) {
    strapiRecipe(slug: { eq: $slug }) {
      title
      ingredients
      instructions
      servings
      slug
      tags {
        tag
      }
      picture {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 500
            cropFocus: ATTENTION
            fit: COVER
          ) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
